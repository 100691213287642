import {
  ChangeEvent,
  FC,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';

import { ButtonFPot, FormError, Modal, TextArea } from '@components';

import classes from './PostcardModal.module.scss';

interface IPostcardModalProps {
  labelPostcard: string;
  postcardMessage?: string;
  children: ReactNode;
  onSubmit: (e: SyntheticEvent | MouseEvent, message?: string) => void;
  preventOpenModal?: boolean;
  classNameWrapper?: string;
}

const PostcardModal: FC<IPostcardModalProps> = ({
  postcardMessage,
  children,
  onSubmit,
  preventOpenModal,
  labelPostcard,
  classNameWrapper
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(postcardMessage || '');
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const textAreaRef = useRef() as MutableRefObject<HTMLTextAreaElement>;

  useEffect(() => {
    if (textAreaRef.current && isOpen) {
      setTextareaHeight(`${textAreaRef.current?.scrollHeight}px`);
    }
  }, [isOpen]);

  function handleCloseModal() {
    setIsOpen(false);
    setPending(false);
    if (!postcardMessage) {
      setMessage('');
    }
  }

  function handleOpenModal(e: SyntheticEvent | MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    setMessage(postcardMessage || '');
    if (!preventOpenModal) {
      setIsOpen(true);
    } else {
      if (onSubmit) {
        setErrorMessage('');
        onSubmit(e);
        handleCloseModal();
      }
    }
  }

  const handleSubmit = (e: SyntheticEvent | MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onSubmit) {
      setErrorMessage('');
      onSubmit(e, message);
      handleCloseModal();
    }
  };

  const handleTextareaInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const textareaEl = e.target;
    if (textareaEl?.scrollHeight > textareaEl?.getBoundingClientRect().height) {
      setTextareaHeight(`${textareaEl?.scrollHeight}px`);
    }
    if (textareaEl.textLength === 0) {
      setTextareaHeight('auto');
    }
    setMessage(textareaEl.value);
  };

  return (
    <>
      <Modal open={isOpen} closeModal={handleCloseModal} classNameContainer={classes.container}>
        <h3 className={classes.dialogTitle}>{labelPostcard}</h3>
        <form>
          <p className={classes.labelTextArea}>Текст листівки</p>
          <TextArea
            onChange={handleTextareaInput}
            value={message}
            classNameWrapper={classes.textArea}
            innerRef={textAreaRef}
            style={{ height: textareaHeight }}
          />
          <FormError message={errorMessage} className={classes.error} />
          <ButtonFPot label="Зберегти" disabled={pending} spinner={pending} fullWidth onClick={handleSubmit} />
        </form>
      </Modal>
      <div onClick={handleOpenModal} className={clsx(classes.editBtn, classNameWrapper)}>
        {children}
      </div>
    </>
  );
};

export { PostcardModal };
