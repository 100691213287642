import { ChangeEvent, FC, useState } from 'react';
import { type Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import {
  DatePickerProps,
  LocalizationProvider,
  DatePicker as MaterialDatePicker,
  MobileDatePicker,
  PickersLocaleText
} from '@mui/x-date-pickers';

import { isMobileDevice } from '@lib/constants/environment';
import { getDayByUkraineTimeZone } from '@utils/formatting';
// import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
import { Icon } from '../../icon/Icon';

import classes from './DatePicker.module.scss';

interface IProps extends Omit<DatePickerProps<string>, 'value' | 'minDate' | 'maxDate' | 'onChange'> {
  classes?: object;
  className?: string;
  isValid?: boolean;
  value?: string | Dayjs | null;
  minDate?: string | Dayjs;
  maxDate?: string | Dayjs;
  onChange: (event: string | Dayjs | ChangeEvent<Element> | null) => void;
}

const OpenPickerIcon = (props: { size?: number } | object) => (
  <Icon className={classes.openPickerBtn} type="calendar" size={24} {...props} />
);

// const renderWeekPickerDay = (date: Date, selectedDates: Array<Date | null>, pickersDayProps?: PickersDayProps<Date>) => {
//   return (
//     <PickersDay
//       {...pickersDayProps}
//       sx={{
//         [`&&.${pickersDayClasses.selected}`]: {
//           backgroundColor: '#691B33',
//           fontWeight: 500
//         }
//       }}
//     />
//   );
// };

export const DatePicker: FC<IProps> = ({
  isValid,
  value,
  minDate,
  maxDate,
  className,
  onChange,
  disablePast,
  shouldDisableDate,
  ...otherProps
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (!otherProps.disabled) {
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const customPtBRLocaleText: Partial<PickersLocaleText<string>> = {
    okButtonLabel: 'Застосувати',
    cancelButtonLabel: 'Відмінити'
  };

  const shouldDisableDateWrapper = (date: Dayjs | string): boolean => {
    if ('function' !== typeof shouldDisableDate) {
      return false;
    }
    return shouldDisableDate(getDayByUkraineTimeZone(date).toString());
  };

  return (
    <LocalizationProvider localeText={customPtBRLocaleText}>
      {isMobileDevice(window.navigator) ? (
        <MobileDatePicker
          slotProps={{
            dialog: {
              className: classes.paper,
              classes: { container: classes.dialogContainer, paper: classes.paperContainer }
            },
            layout: { className: classes.layout },
            textField: {
              error: isValid,
              onClick: handleClick,
              inputProps: { readOnly: true }
            }
          }}
          className={className}
          closeOnSelect={true}
          views={['day']}
          format="DD.MM.YYYY"
          onChange={onChange}
          onOpen={handleClick}
          onClose={onClose}
          open={open}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <OpenPickerIcon size={29} />
                }}
              />
            )
          }}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePast}
          shouldDisableDate={shouldDisableDateWrapper}
          label="Дата"
          timezone='Europe/Kyiv'
          // renderDay={renderWeekPickerDay}
        ></MobileDatePicker>
      ) : (
        <MaterialDatePicker
          slotProps={{
            dialog: {
              className: classes.paper,
              classes: { container: classes.dialogContainer, paper: classes.paperContainer }
            },
            desktopPaper: { className: classes.desktopPaper },
            layout: { className: classes.layout },
            textField: {
              error: isValid,
              onClick: handleClick,
              inputProps: { readOnly: true }
            }
          }}
          className={className}
          closeOnSelect={true}
          views={['day']}
          format="DD.MM.YYYY"
          slots={{
            openPickerButton: OpenPickerIcon
          }}
          onChange={onChange}
          onOpen={handleClick}
          onClose={onClose}
          open={open}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePast}
          shouldDisableDate={shouldDisableDateWrapper}
          label="Дата"
          timezone='Europe/Kyiv'
          // renderDay={renderWeekPickerDay}
        />
      )}
    </LocalizationProvider>
  );
};
