import { FC, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

import { postFeedbackProductData } from '@api/feedback';
import { Icon } from '@components';
import FeedbackForm from '@components/modals/feedback-form/FeedbackForm';
import { ProductPrice } from '@components/shared/product-price/ProductPrice';
import { ProductStockStatus } from '@components/shared/product-stock-status/ProductStockStatus';
import { STATUS_LABELS } from '@lib/constants/common-constants';
import { PRODUCTION_ENV } from '@lib/constants/environment';
import { IFeedbackPayload, IImage } from '@types';

import classes from './OrderProductItem.module.scss';

const FeedbackModal = dynamic(() => import('@components/modals/feedback-modal/FeedbackModal'));

interface IProps {
  name: string;
  seoKey: string;
  price: number;
  qty: number;
  image?: IImage;
  specialPrice?: number;
  specialPriceEndDate?: string;
  postcardMessage?: string;
  displayCode: string;
  productId?: string;
  productReview?: boolean;
  feedbackAvailable?: boolean;
  togglePostcardAvailable?: boolean;
  inStockStatusAvailable?: boolean;
  inStockStatus: keyof typeof STATUS_LABELS;
  isFinalizeOrder?: boolean;
  regularPrice?: number;
}

export const OrderProductItem: FC<IProps> = ({
  name,
  price,
  qty,
  image,
  seoKey,
  specialPrice,
  postcardMessage,
  displayCode,
  productReview,
  productId,
  feedbackAvailable,
  togglePostcardAvailable,
  inStockStatus,
  inStockStatusAvailable,
  specialPriceEndDate,
  isFinalizeOrder,
  regularPrice
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successSentReview, setSuccessSentReview] = useState(false);
  const [isPending, setPending] = useState(false);
  const [togglePostcard, setTogglePostcard] = useState(false);

  const imgSrc = image && image.thumbnail ? image.thumbnail : '/assets/images/flower-no-image.svg';

  const subtitleContent = (
    <Link href={`/${seoKey}`} className={classes.subtitleContent}>
      <Image src={imgSrc} alt={name} width={80} height={80} className={classes.img} />
      <p className={classes.name}>{name}</p>
    </Link>
  );

  const onSubmit = (data: IFeedbackPayload) => {
    setErrorMessage('');
    if (data && data.images.length) {
      const formData = new FormData();
      const images = data.images;
      images.map((file) => {
        formData.append('images', file);
      });
      formData.append('product', productId || '');
      formData.append('name', data.name);
      formData.append('phone', data.phone);
      formData.append('rating', String(data.rating));
      formData.append('review', data.review);
      setPending(true);
      postFeedbackProductData(formData)
        .then((feedback) => {
          if (feedback?.review) {
            setSuccessSentReview(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error);
        })
        .finally(() => setPending(false));
    }
  };

  const handeTogglePostcard = () => {
    setTogglePostcard((prevState) => !prevState);
  };

  function countLinesPostcardMessage(text?: string) {
    return 2 < (`${text}`.trim().match(/\r?\n/g) || []).length + 1;
  }

  const hideArrow = countLinesPostcardMessage(postcardMessage);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Link href={`/${seoKey}`}>
          <Image width={80} height={80} src={imgSrc} alt={name} className={classes.imgProduct} quality={100} />
        </Link>
        <div className={classes.info}>
          <Link href={`/${seoKey}`}>
            <h3 className={classes.name}>{name}</h3>
          </Link>
          <p className={clsx(classes.productCode, { [classes.containerProductCode]: inStockStatusAvailable })}>
            Артикул: {displayCode}
          </p>
          {!inStockStatusAvailable ? (
            <ProductStockStatus status={inStockStatus} className={classes.stockStatus} />
          ) : null}
          <div className={clsx(classes.prices, { [classes.postcardContainer]: !!postcardMessage })}>
            {postcardMessage ? (
              <div className={classes.postcardWrapper}>
                <p
                  className={clsx(classes.postcardMessage, {
                    [classes.showFullPostcard]: togglePostcard && togglePostcardAvailable
                  })}
                >
                  {postcardMessage}
                </p>
                {togglePostcardAvailable && hideArrow ? (
                  <Icon
                    type="chevronDown"
                    width={22}
                    height={18}
                    className={clsx(classes.arrow, { [classes.arrowRotate]: togglePostcard })}
                    onClick={handeTogglePostcard}
                  />
                ) : null}
              </div>
            ) : (
              <>
                <span className={classes.productQty}>x{qty}</span>
                <span className={classes.totalPrice}>
                  <ProductPrice
                    price={isFinalizeOrder ? Number(regularPrice && regularPrice * qty) : Number(price * qty)}
                    specialPrice={Number(specialPrice && specialPrice * qty)}
                    specialPriceEndDate={specialPriceEndDate}
                    mediumPrice
                    isFinalizeOrder={isFinalizeOrder}
                  />
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      {!productReview && !PRODUCTION_ENV && feedbackAvailable ? (
        <FeedbackModal
          form={<FeedbackForm onSubmit={onSubmit} errorMessage={errorMessage} disabledButton={isPending} />}
          buttonLabel="Залишити відкук про товар"
          title="Залишити відгук"
          successMessage="Він з’явиться на сайті після модерації"
          successTitle="Дякуємо за відгук"
          successSentReview={successSentReview}
          classNameFeedbackBtn={classes.feedbackLabel}
          subtitle={subtitleContent}
        />
      ) : null}
    </div>
  );
};
